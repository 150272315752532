import React from 'react';
import '../App.css';

const ListaUtiles = () => {
  const pdfFiles = [
    { title: "Lista de Útiles Pre-Kínder 2025", url:"/pdfs/LISTA  PKINDER 2025.pdf"},
    { title: "Lista de Útiles Kínder 2025", url: "/pdfs/LISTA KÍNDER 2025.pdf" },
    { title: "Lista de Útiles 1° Año Básico 2025", url: "/pdfs/LISTA 1 2025.pdf" },
    { title: "Lista de Útiles 2° Año Básico 2025", url: "/pdfs/LISTA 2 2025.pdf" },
    { title: "Lista de Útiles 3° y 4° Año Básico 2025", url: "/pdfs/LISTA 3y4 2025.pdf" },
    { title: "Lista de Útiles 5° a 8° Año Básico 2025", url: "/pdfs/LISTA 5y8 2025.pdf" },
   
    // Agrega más documentos según sea necesario
  ];

  return (
    <div id="listacursos" className="cursos-list">
      <h2>Lista de Útiles Escolares 2025</h2>
      <ul>
        {pdfFiles.map((pdf, index) => (
          <li key={index}>
            <a href={pdf.url} target="_blank" rel="noopener noreferrer">
              {pdf.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListaUtiles;