import React from 'react';
import '../App.css'; // Asegúrate de tener el archivo de estilos
import logo from '../img/logo_escuela.png';

class YouTubeVideoList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            urls: [
                'https://www.youtube.com/watch?v=jDsZ8jZAfsU',
                'https://www.youtube.com/watch?v=BnS4Fa6105E',
                'https://www.youtube.com/watch?v=7QJ9nNyzDik',
                'https://www.youtube.com/watch?v=oZd220nOlZc',
                'https://www.youtube.com/watch?v=GK8Yx8Mo0Bc',
                'https://youtu.be/PsZhROrJq7o',
                'https://www.youtube.com/watch?v=5pOC58xCiCA',
                'https://www.youtube.com/watch?v=YbhDZBxGG54',
                'https://www.youtube.com/watch?v=yCmkUQ3jT0U',
                'https://www.youtube.com/watch?v=soADQotU_5c',
                'https://www.youtube.com/watch?v=XWl5j7wZv84',
                'https://www.youtube.com/watch?v=nI3z7kTbsnU',
                'https://www.youtube.com/watch?v=kZ7IkDY5EZk',
                'https://www.youtube.com/watch?v=9vmXY_KUhH8',
                'https://www.youtube.com/watch?v=_ZOAevMy7yo',
                'https://www.youtube.com/watch?v=2CWUrNVx6S0',
                'https://www.youtube.com/watch?v=ERLCunHe9dA',
                'https://www.youtube.com/watch?v=bpl3TphHOfQ',
                'https://www.youtube.com/watch?v=42zBH9n1FKM',
                'https://www.youtube.com/watch?v=MzQF0RNg4Jk',
                'https://www.youtube.com/watch?v=upRUqHt44Zc',
                'https://www.youtube.com/watch?v=n4S4_qOkFhA',
                'https://www.youtube.com/watch?v=_naNticc0Yg',
                 
            ],
            videoNames: [
                'Graduación Kinder año 2024',
                'Graduación 8° Básico año 2024',
                'Feria de la Ciencia, Tecnología y Medio Ambiente 2024',
                'Encuentro Intercultural 2024',
                'Talleres Jec 2024',
                'VII Café Literario 2024',
                'Reportaje Planta Fotovoltaica Escuela Nueva Zelandia 2023',
                'Licenciatura 8° Básico Año 2023',
                'Graduación Kinder Año 2023',
                'III Feria de las Ciencias, Tecnología y Medio Ambiente Año 2023',
                'VI Café Literario Año 2023 ',
                'Encuentro Intercultural Año 2023',
                'Aniversario Escuela N° 57 Año 2023',
                'Licenciatura 8° Básico Año 2022',

                'Graduación Kinder Año 2022',

                'Feria Medio Ambiental Año 2022',
                'Aniversario Escuela N° 56 Año 2022',
                'Graduación Kinder Año 2021',
                'Fiestas Patrias 2021',
                'Feria Cientifica Año 2021',
                'Licenciatura 8° Básico Año 2020',
                'Tercer Café Literario Año 2017',
                'Segundo Café Literario Año 2016',
            ],
        };
    }

    extractVideoId = (url) => {
        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regex);
        return match ? match[1] : '';
    };

    render() {
        const { urls, videoNames } = this.state;

        return (
            <div className="videos-container" id="videos-container">
                <h2 className="galeria-title">Galería Audiovisual</h2>
                <div className="videos-list">
                    <div className="video-cards">
                        {urls.map((url, index) => (
                            <div
                                key={index}
                                className="youtube-video-card"
                                style={{
                                    backgroundImage: `url(https://img.youtube.com/vi/${this.extractVideoId(url)}/hqdefault.jpg)`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                                onClick={() => window.open(url, '_blank')}
                            >
                                <div className="youtube-overlay">
                                    <h3>{videoNames[index]}</h3>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="channel-link">
                    <img
                        src={logo}
                        alt="Logo del canal de YouTube"
                        className="channel-logo"
                    />
                    <div className="channel-link-btn-wrapper">
                        <h3 className="galeria-title">Visita Nuestro Canal de YouTube</h3>
                        <a
                            href="https://www.youtube.com/channel/UCv9kvVQXl1zXASkSQfra55w"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="channel-link-btn"
                        >
                            Ir al canal
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default YouTubeVideoList;
